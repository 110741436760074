import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "grid",
      "style": {
        "position": "relative"
      }
    }}>{`Grid`}</h1>
    <p>{`Os `}<em parentName="p">{`grids`}</em>{` são usados para orientar a construção da página através de uma série de linhas e colunas que organizam seu conteúdo. O uso consistente de um sistema de `}<em parentName="p">{`grids`}</em>{` fornece a base para uma página harmônica com elementos bem posicionados na tela. O Bold usa um sistema de 12 colunas, variando o `}<em parentName="p">{`gutter`}</em>{` e o tamanho das colunas de acordo com a resolução da página.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/grid-columns.png",
        "alt": "Colunas da grade"
      }}></img></p>
    <h2 {...{
      "id": "menu-lateral",
      "style": {
        "position": "relative"
      }
    }}>{`Menu lateral`}</h2>
    <p>{`Quando a página tiver um menu lateral fixo, ele será posicionada fora dos limites do `}<em parentName="p">{`grid`}</em>{`, incluindo as margens. O conteúdo principal é movido de lado para fornecer espaço para o menu lateral e o `}<em parentName="p">{`grid`}</em>{` e a área de conteúdo são condensadas.`}</p>
    <h2 {...{
      "id": "breakpoints",
      "style": {
        "position": "relative"
      }
    }}>{`Breakpoints`}</h2>
    <p>{`O número de colunas é independente do tamanho da página, e é sempre 12.`}</p>
    <table>
  <thead>
    <tr>
      <th>Resolução</th>
      <th>Colunas</th>
      <th>Gutter</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1024px</td>
      <td>12</td>
      <td>16px</td>
    </tr>
    <tr>
      <td>1280px</td>
      <td>12</td>
      <td>32px</td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas:`}</h2>
    <ul>
      <li parentName="ul">{`Use múltiplos de 8px ao definir tamanhos, espaçamento e posições do elemento;`}</li>
      <li parentName="ul">{`Sempre que possível, alinhe os elementos na vertical e na horizontal;`}</li>
      <li parentName="ul">{`Use um bom espaçamento entre os blocos de conteúdo da página. Isso cria um agrupamento visual entre itens semelhantes e facilita a navegação.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      